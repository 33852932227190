<template>
  <div
    class="form-builder__portal"
    :class="schema.styleClasses || ''"
  >
    <portal-target :name="schema.portal" />
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
